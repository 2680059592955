import React, { useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isIOS } from 'react-device-detect';
import { selectCurrentUser, selectPolicies } from 'store/user/authentication.slice';
import { useToggle } from 'utils/hooks/useToggle';
import { useEnhancedNav } from 'utils/hooks/useEnhancedNav';
import { useLocation, generatePath } from 'react-router-dom';
import { mobileApiCall } from 'services/mobileService';
import { accountsApi } from 'store/user/accounts/accounts.api';
import { ROUTES } from 'vars/const/ROUTES';
import { setPennyJarBackRoute } from 'store/user/accounts/accounts.slice';
import { IAccountItem, IFeatureItem, TAddAccountType } from 'store/user/accounts/accounts.types';
import { useAccounts } from 'utils/hooks/useAccounts';
import { ConfirmJointAccountModal } from 'views/Account/AccountInformation/Modals/editAccount/ConfirmJointAccountModal/ConfirmJointAccountModal';
import { useKYC } from 'utils/hooks/useKYC';
import { Loader } from 'components/general/Loader/Loader';
import { PageFooter } from 'components/layouts/Page/PageFooter/PageFooter';
import { PageHeader } from 'components/layouts/Page/DesktopPageHeader/PageHeader';
import { BodyText, Title } from 'components/general/Typography';
import { SimpleAccountSelectSheet } from 'components/general/Accounts/AccountSelect/SimpleAccountSelectSheet/SimpleAccountSelectSheet';
import { filterUnique, mergeArrays } from 'utils/helpers/arrays';
import { useGetDoMoreCardsDisclaimersQuery } from 'store/user/properties/systemProperties.api';
import { useAllCashAccountsFeatures } from 'utils/hooks/useAllCashAccountsFeatures';
import { isUnder18 } from 'utils/helpers/date';
import { SLink } from 'views/OpenCashAccount/MyInfo/MyDetailsPage/MyDetailsPage.styles';
import { lsGetItem } from 'utils/helpers/storage';
import { useLazyAprilByAppNameQuery } from 'store/user/users.api';
import { useAddUserPropertiesMutation, useLazyGetUserPropertyQuery } from 'store/user/properties/userProperties.api';
import { DoMorePage } from './DoMore.styles';
import { OptionItem } from './OptionItem/OptionItem';
import { useAprilInit } from './use-april-init';

type TApplicationNames = 'PAYCHECK_ESTIMATION' | 'PAYCHECK_OPTIMIZATION' | 'FILING';

const INST_URL = 'https://www.instagram.com/wearepercapita/';
const FB_URL = 'https://www.facebook.com/WeArePercapita/';
const SURVEY_URL = 'https://www.surveymonkey.com/r/percapitafeedback';

export const DoMore = () => {
  const { t } = useTranslation('doMore');
  const dispatch = useDispatch();
  const { navigate } = useEnhancedNav();
  const { pathname } = useLocation();

  const { internalAccountsGroups, internalAccounts, cashAccounts, isLoading: isAccountDataLoading } = useAccounts();
  const { isLoading: isKycLoading, currentCAOStepUrl, KYCStatus } = useKYC();
  const { roundUpFeatures, overdraftFeatures, isLoading: isFeaturesLoading } = useAllCashAccountsFeatures();
  const policies = useSelector(selectPolicies);
  const user = useSelector(selectCurrentUser);
  const isUserUnder18 = !!user.birthday && isUnder18(new Date(user.birthday));
  const { cards, disclaimers } = useGetDoMoreCardsDisclaimersQuery()?.data ?? {};
  const [getAprilKey, aprilKeyResponse] = useLazyAprilByAppNameQuery();
  const [setUserProperty] = useAddUserPropertiesMutation();
  const [getUserProperty] = useLazyGetUserPropertyQuery();
  const { userId } = user;
  const isMobileApp = lsGetItem('isMobileApp');
  const initApril = useAprilInit();

  const selectAccountForJointSheet = useToggle(false);
  const selectAccountForDirectDepositSheet = useToggle();
  const confirmJointAccountModal = useToggle(false, { accountForJointId: '', accountName: '' });
  const ownerJointInvitesQuery = accountsApi.useGetOwnerJointInvitesQuery();
  const accountsWithJointInvites = ownerJointInvitesQuery.data?.map((invite) => invite.accountId) ?? [];
  const jointCashAccountsIds = cashAccounts?.filter((item) => item.isCashType && item?.isJoint).map((acc) => acc.accountId);
  const accountsDisabledForJointOpening = mergeArrays(accountsWithJointInvites, jointCashAccountsIds);
  const nonJointCashAccountsCount = cashAccounts.length - accountsDisabledForJointOpening.length;

  const isAddSecondaryCashEnabled = cashAccounts?.length < 2 && cashAccounts?.length !== 0 && !isUserUnder18;
  const hasCashAccounts = internalAccounts?.some((item: IAccountItem) => item.isCashType);
  const hasNeedsAccounts = internalAccounts?.some((item: IAccountItem) => item.isNeedsType);
  const hasGoalsAccounts = internalAccounts?.some((item: IAccountItem) => item.isGoalsType);
  const hasAccountsAvailableForPennyJar = roundUpFeatures?.some(
    (item: IFeatureItem) => !item.isEnabled && internalAccounts.some((acc) => (acc.isNeedsType || acc.isGoalsType) && acc.parentAccountId === item?.accountId)
  );
  const isDisplayPennyJar = policies?.PennyJarEnabled && hasAccountsAvailableForPennyJar;
  const isDisplayCashCushion = overdraftFeatures.some((feature) => feature.overdraft.isEligible && !feature.isEnabled);
  const { IsTaxEstimatorEnabled, IsTaxFilingEnabled } = policies;
  const isDisplayInternationalTransferCard = !!isMobileApp && policies?.InternationalTransferEnabled;
  const hasCashWithoutGoals = internalAccountsGroups.some((group) => !group.goals);
  const hasCashWithoutNeeds = internalAccountsGroups.some((group) => !group.needs);
  const isLoading = isAccountDataLoading || isKycLoading || isFeaturesLoading;

  const itemsList = useMemo(() => {
    const list = [];

    if (!KYCStatus && !hasCashAccounts) {
      list.push('cashAccount');
    }

    if (hasCashAccounts) {
      list.push('directDeposit');
    }

    if (nonJointCashAccountsCount > 0) {
      list.push('jointAccount');
    }

    if (hasCashWithoutGoals || !hasCashAccounts) {
      list.push('goalsAccount');
    }

    if (hasCashWithoutNeeds || !hasCashAccounts) {
      list.push('needsAccount');
    }

    if (isDisplayCashCushion) {
      list.push('cashCushion');
    }

    if (isDisplayPennyJar) {
      list.push('pennyJar');
    }

    if (isAddSecondaryCashEnabled) {
      list.push('secondCashAccount');
    }

    return list;
  }, [KYCStatus, hasCashAccounts, nonJointCashAccountsCount, hasCashWithoutGoals, hasCashWithoutNeeds, isDisplayCashCushion, isDisplayPennyJar, isAddSecondaryCashEnabled]);

  const footerDisclaimers = useMemo(() => {
    const disclaimersTexts: string[] = [];

    if (itemsList && cards && disclaimers) {
      const disclaimerNames: string[] = [];
      itemsList.forEach((name) => {
        const disclaimerName = cards.find((card) => card.name === name)?.disclaimer;
        if (disclaimerName) {
          disclaimerNames.push(disclaimerName);
        }
      });

      filterUnique(disclaimerNames).forEach((discName: string) => {
        const disclaimerText = disclaimers.find((disclaimer) => disclaimer.name === discName)?.text;
        if (disclaimerText) {
          disclaimersTexts.push(disclaimerText);
        }
      });
    }

    return disclaimersTexts;
  }, [itemsList, cards, disclaimers]);

  const addAccount = (type: TAddAccountType) => {
    navigate(generatePath(ROUTES.addNeedsGoalsAccount.path, { type }));
  };

  const handleOnPennyJar = () => {
    dispatch(setPennyJarBackRoute(ROUTES.doMore.path));
    navigate(ROUTES.pennyJar.path);
  };

  const selectCashAccountForJoint = (account: IAccountItem) => {
    confirmJointAccountModal.setData({ accountForJointId: account.accountId, accountName: account.name });
    confirmJointAccountModal.show();
    selectAccountForJointSheet.hide();
  };

  const handleSelectAccountForDirectDeposit = ({ accountId }: IAccountItem) => {
    navigate(ROUTES.setUpDeposit.path, { searchParams: { accountId } });
  };

  const handleDirectDepositClick = () => {
    if (cashAccounts.length > 1) {
      selectAccountForDirectDepositSheet.show();
    } else {
      handleSelectAccountForDirectDeposit(cashAccounts[0]);
    }
  };

  const handleClickCommunityLink = (url: string, inApp = false) => {
    if (isMobileApp) {
      const browserData = {
        url,
        inApp,
      };
      mobileApiCall('showFullBrowser', JSON.stringify(browserData));
    } else {
      window.open(url, '_blank');
    }
  };

  const handleTaxServiceClick = async (route: string, aprilKey: TApplicationNames) => {
    const userVisitedTaxServiceResponse = await getUserProperty(aprilKey);
    console.log(`user property ${aprilKey} is ${userVisitedTaxServiceResponse.data}`);
    if (userVisitedTaxServiceResponse.data === 'true') {
      if (isMobileApp) {
        console.log(`mobile api call ${aprilKey}`);
        mobileApiCall('showApril', aprilKey);
      } else {
        console.log(`webapp call ${aprilKey}`);
        await getAprilKey(aprilKey);
      }
    } else {
      console.log(`property ${aprilKey} is falsy, setting property to true, trying redirect`);
      await setUserProperty({ id: userId, propertyName: aprilKey, value: true });
      navigate(route);
    }
  };

  const handleTaxFilerClick = () => handleTaxServiceClick(ROUTES.taxFiler.path, 'FILING');
  const handleTaxEstimatorClick = () => handleTaxServiceClick(ROUTES.taxPlanner.path, 'PAYCHECK_OPTIMIZATION');

  useEffect(() => {
    if (aprilKeyResponse.data?.token) {
      initApril(aprilKeyResponse.data?.token);
    }
  }, [aprilKeyResponse]);

  return (
    <DoMorePage className="do-more-page">
      {isLoading && <Loader />}
      <div className="do-more-page__content">
        <PageHeader title={t('MoreWithPercapita')} />

        <div className="main-content">
          <Title size="sL" font="Poppins" fontWeight="SB" className="group-title">
            {t('CashAccountAddons')}
          </Title>

          <div className="options">
            {/* Cash Account */}
            {itemsList.includes('cashAccount') && (
              <OptionItem
                title={t('OpenACashAccount')}
                description={t('ThePercapitaCashAccountAndDebitCardMakeAccessing')}
                className="cash-account"
                onClick={() => navigate(currentCAOStepUrl || ROUTES.starter.path)}
              />
            )}

            {/* Direct Deposit */}
            {hasCashAccounts && <OptionItem title={t('SetUpDirectDeposit')} description={t('HaveYourPayrollAutomaticallyDeposited')} className="direct-deposit" onClick={handleDirectDepositClick} />}

            {/* Joint Accounts */}
            {itemsList.includes('jointAccount') && (
              <OptionItem
                title={t('AddAJointAccountholder')}
                description={t('ShareYourCashAccount')}
                className="joint-accountholder"
                onClick={selectAccountForJointSheet.show}
                isDisabled={!policies?.JointAccountEnabled}
              />
            )}

            {/* Goals Account */}
            {itemsList.includes('goalsAccount') && (
              <OptionItem
                title={t('OpenAGoalsAccount')}
                description={t('SetAsideMoneyForFutureFinancialGoals')}
                className="goals-account"
                onClick={() => addAccount('goals')}
                isDisabled={!hasCashAccounts}
              />
            )}

            {/* Needs Account */}
            {itemsList.includes('needsAccount') && (
              <OptionItem
                title={t('OpenANeedsAccount')}
                description={t('SetAsideMoneyIntendedForMonthlyExpenses')}
                className="needs-account"
                onClick={() => addAccount('needs')}
                isDisabled={!hasCashAccounts}
              />
            )}

            {/* Cash Cushion */}
            {itemsList.includes('cashCushion') && (
              <OptionItem
                title={t('OptInToCashCushion')}
                description={t('AllowYourAccountToGoNegativeUp')}
                className="cash-cushion"
                onClick={() => navigate(ROUTES.cashCushion.path, { state: { backPage: pathname } })}
              />
            )}

            {/* Penny Jar */}
            {itemsList.includes('pennyJar') && (
              <OptionItem
                title={t('SetUpPennyJar')}
                description={t('RoundUpEachPurchaseMade')}
                className="penny-jar"
                onClick={handleOnPennyJar}
                isDisabled={!((hasCashAccounts && hasNeedsAccounts) || (hasCashAccounts && hasGoalsAccounts) || (hasCashAccounts && hasNeedsAccounts && hasGoalsAccounts))}
              />
            )}

            {/* Second Cash Account */}
            {itemsList.includes('secondCashAccount') && (
              <OptionItem
                title={t('AddNewCashAccount')}
                description={t('OpenANewCashAccount')}
                className="second-cash-account"
                onClick={() => navigate(ROUTES.starter.path, { state: { alreadyHasCash: true }, backUrl: 'current' })}
              />
            )}

            {/* Additional Services */}
            {isDisplayInternationalTransferCard && (
              <>
                <Title size="sL" font="Poppins" fontWeight="SB" marginTop={12} className="group-title">
                  {t('AdditionalServicesTitle')}
                </Title>

                <OptionItem
                  title={t('InternationalTransfers')}
                  description={t('SendMoneyAbroadQuickly')}
                  className="international-transfer"
                  onClick={() => navigate(ROUTES.internationalTransfer.path, { state: { backPage: pathname } })}
                />
              </>
            )}

            {/* Tax Estimator / Tax Filer */}
            {(IsTaxEstimatorEnabled || IsTaxFilingEnabled) && (
              <>
                <Title size="sL" font="Poppins" fontWeight="SB" marginTop={12} className="group-title">
                  {t('Taxes')}
                </Title>

                {IsTaxEstimatorEnabled && (
                  <OptionItem title={t('TaxPlanner')} description={t('TaxPlannerDescription')} topBadge={t('Partner')} className="tax-estimator" onClick={handleTaxEstimatorClick} />
                )}
                {IsTaxFilingEnabled && <OptionItem title={t('TaxFiler')} description={t('TaxFilerDescription')} topBadge={t('Partner')} className="tax-filer" onClick={handleTaxFilerClick} />}
              </>
            )}
          </div>

          <div id="april" />

          <Title size="sL" font="Poppins" fontWeight="SB" marginTop={12} className="group-title">
            {t('PercapitaCommunity')}
          </Title>

          <div className="options">
            <OptionItem title={t('FollowUsInstagram')} description={t('InstagramUpdates')} className="inst" onClick={() => handleClickCommunityLink(INST_URL)} />
            <OptionItem title={t('FollowUsFacebook')} description={t('FollowUsFacebookDescription')} className="fb" onClick={() => handleClickCommunityLink(FB_URL)} />
            <OptionItem title={t('WeLoveIdeas')} description={t('WeLoveIdeasDescription')} className="ideas" onClick={() => handleClickCommunityLink(SURVEY_URL, isIOS)} />
          </div>
        </div>
      </div>

      <PageFooter>
        <BodyText textType="bodyText" color="charcoal70" size="T" fontWeight="R" lineHeight={1.4} paddingRight={5}>
          <Trans i18nKey="Full" ns="footer" components={{ Link: <SLink href="http://www.fdic.gov" /> }} target="_blank" />
        </BodyText>
        {footerDisclaimers.map((disclaimer) => (
          <BodyText textType="bodyText" color="charcoal70" size="T" fontWeight="R" lineHeight={1.4} paddingRight={5} marginTop={15}>
            <span dangerouslySetInnerHTML={{ __html: disclaimer }} />
          </BodyText>
        ))}
      </PageFooter>

      <SimpleAccountSelectSheet
        accounts={cashAccounts}
        isOpen={selectAccountForJointSheet.isActive}
        onClose={selectAccountForJointSheet.hide}
        disableIds={accountsDisabledForJointOpening}
        onSelect={selectCashAccountForJoint}
        title={t('SelectAccountForJointTitle')}
      />

      <SimpleAccountSelectSheet
        accounts={cashAccounts}
        isOpen={selectAccountForDirectDepositSheet.isActive}
        onClose={selectAccountForDirectDepositSheet.hide}
        onSelect={handleSelectAccountForDirectDeposit}
        title={t('SelectAccountForDirectDepositTitle')}
      />

      <ConfirmJointAccountModal open={confirmJointAccountModal.isActive} handleClose={confirmJointAccountModal.hide} {...confirmJointAccountModal?.data} />
    </DoMorePage>
  );
};
